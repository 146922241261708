<template>
  <div>
    <div
      class="modal fade"
      id="modal-form-tep-cancelacion"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cancelar Turno</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-md-12">
                  <label for="odometro">Justificación</label>
                  <input
                    type="text"
                    v-model="form.justificacion_cancelacion"
                    class="form-control form-control-sm"
                    :class="
                      $v.form.justificacion_cancelacion.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <div class="row">
              <button
                class="btn bg-primary"
                v-if="
                  $store.getters.can('tep.turnos.cancelar') && !$v.form.$invalid
                "
                @click="save()"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "TepTurnoCancelacion",
  components: {},
  data() {
    return {
      form: {},
    };
  },
  validations() {
    return {
      form: {
        id: {
          required,
        },
        justificacion_cancelacion: {
          required,
        },
        estado: {
          required,
        },
      },
    };
  },
  methods: {
    llenar_modal_cancelar(turno) {
      this.form = {
        id: turno.id,
        justificacion_cancelacion: turno.justificacion_cancelacion,
        estado: 5,
        det_postulacion_id: turno.postulacion.id,
      };
    },

    save() {
      this.$parent.cargando = true;
      axios({
        method: "POST",
        url: "/api/tep/turnos/cancelarTurno",
        data: this.form,
      })
        .then(() => {
          this.form = {};
          this.$refs.closeModal.click();
          this.$parent.cargando = false;
          this.$parent.getIndex();
          this.$swal({
            icon: "success",
            title: `Turno cancelado con Éxito...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$parent.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
  },
  mounted() {},
};
</script>
